(function() {
    'use strict';

    angular
        .module('shared.util')
        .constant('CACHE_BUSTER', window.CACHE_BUSTER)
        .run(addCacheBusterToRootScope);

    addCacheBusterToRootScope.$inject = ['$rootScope', 'CACHE_BUSTER'];

    function addCacheBusterToRootScope($rootScope, CACHE_BUSTER) {
        $rootScope.CACHE_BUSTER = CACHE_BUSTER;
    }
})();
