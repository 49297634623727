(function() {
    'use strict';

    angular
        .module('shared.api')
        .provider('scopeService', ScopeServiceProvider);

    function ScopeServiceProvider() {
        this.$get = scopeServiceFactory;

        scopeServiceFactory.$inject = [
            '$q',
            '$rootScope',
        ];

        function scopeServiceFactory() {
            var di = diHelper(scopeServiceFactory.$inject, arguments);

            return new ScopeService(di);
        }
    }

    function ScopeService(di) {
        var scope = null;
        var waitDefer = null;

        this.getDistrictAdmin = getDistrictAdmin;
        this.getDistrictAdmins = getDistrictAdmins;
        this.getSchoolAdmin = getSchoolAdmin;
        this.getSchoolAdmins = getSchoolAdmins;
        this.getTeacher = getTeacher;
        this.getTeachers = getTeachers;
        this.hasMultipleDistricts = hasMultipleDistricts;
        this.hasMultipleSchools = hasMultipleSchools;
        this.hasMultipleClassrooms = hasMultipleClassrooms;
        this.hasSingleDistrict = hasSingleDistrict;
        this.hasSingleSchool = hasSingleSchool;
        this.hasSingleClassroom = hasSingleClassroom;
        this.isAdmin = isAdmin;
        this.isCleverAware = isCleverAware;
        this.isDistrictAdmin = isDistrictAdmin;
        this.isDistrictSuperadmin = isDistrictSuperadmin;
        this.isSchoolAdmin = isSchoolAdmin;
        this.isSysadmin = isSysadmin;
        this.isTeacher = isTeacher;
        this.setScope = setScope;
        this.wait = wait;

        function getDistrictAdmin() {
            return _.first(getDistrictAdmins());
        }

        function getDistrictAdmins() {
            if (isSysadmin()) {
                return [];
            }

            return _.get(scope, 'districtAdmins', []);
        }

        function getSchoolAdmin() {
            return _.first(getSchoolAdmins());
        }

        function getSchoolAdmins() {
            if (isSysadmin()) {
                return [];
            }

            return _.get(scope, 'schoolAdmins', []);
        }

        function getTeacher() {
            return _.first(getTeachers());
        }

        function getTeachers() {
            return _.get(scope, 'teachers', []);
        }

        function hasMultipleClassrooms() {
            return _.size(getTeachers()) > 1;
        }

        function hasMultipleDistricts() {
            return isSysadmin() || _.size(getDistrictAdmins()) > 1;
        }

        /**
         * @returns {boolean} true if the user can see multiple schools (even if they don't exist)
         *   false otherwise.
         */
        function hasMultipleSchools() {
            if (isSysadmin()) {
                return true;
            }

            if (isDistrictAdmin()) {
                return true;
            }

            if (_.size(getSchoolAdmins()) > 1) {
                return true;
            }

            return false;
        }

        function hasSingleClassroom() {
            return _.size(getTeachers()) === 1;
        }

        function hasSingleDistrict() {
            return _.size(getDistrictAdmins()) === 1;
        }

        function hasSingleSchool() {
            return !hasMultipleSchools() && _.size(getSchoolAdmins()) === 1;
        }

        function isAdmin() {
            return isSysadmin() || isDistrictAdmin() || isSchoolAdmin();
        }

        function isCleverAware() {
            if (isSysadmin()) {
                return true;
            }

            var cleverAware;

            cleverAware = _.some(getDistrictAdmins(), function(admin) {
                return _.get(admin, 'district.isClever');
            });

            if (cleverAware) {
                return true;
            }

            cleverAware = _.some(getSchoolAdmins(), function(admin) {
                return admin.school.isClever || admin.school.district.isClever;
            });

            if (cleverAware) {
                return true;
            }

            return false;
        }

        function isDistrictAdmin(district) {
            return !!_.find(getDistrictAdmins(), function(districtAdmin) {
                if (district) {
                    return district.id === districtAdmin.district.id;
                }

                return true;
            });
        }

        function isDistrictSuperadmin(district) {
            return !!_.find(getDistrictAdmins(), function(districtAdmin) {
                if (!districtAdmin.isSuper) {
                    return false;
                }

                if (district) {
                    return district.id === districtAdmin.district.id;
                }

                return true;
            });
        }

        function isSchoolAdmin() {
            return _.size(getSchoolAdmins()) > 0;
        }

        function isSysadmin() {
            return !!_.get(scope, 'sysadmin');
        }

        function isTeacher() {
            return _.size(getTeachers()) > 0;
        }

        function setScope(updatedScope) {
            scope = updatedScope;

            if (waitDefer) {
                waitDefer.resolve();
                waitDefer = null;
            }

            di.$rootScope.$broadcast('scopeService.updated');
        }

        function wait() {
            if (scope) {
                return di.$q.resolve();
            }

            if (!waitDefer) {
                waitDefer = di.$q.defer();
            }

            return waitDefer.promise;
        }
    }
})();
