(function() {
    'use strict';

    window.countAngularWatchers = function() {
        var root = angular.element(document.getElementsByTagName('body'));

        var watchers = [];

        var countRecursive = function(element) {
            angular.forEach(['$scope', '$isolateScope'], function(scopeProperty) {
                if (element.data() && _.has(element.data(), [scopeProperty])) {
                    angular.forEach(element.data()[scopeProperty].$$watchers, function(watcher) {
                        watchers.push(watcher);
                    });
                }
            });

            angular.forEach(element.children(), function(childElement) {
                countRecursive(angular.element(childElement));
            });
        };

        countRecursive(root);

        // Remove duplicate watchers
        var watchersWithoutDuplicates = [];
        angular.forEach(watchers, function(item) {
            if (watchersWithoutDuplicates.indexOf(item) < 0) {
                watchersWithoutDuplicates.push(item);
            }
        });

        return watchersWithoutDuplicates.length;
    };
})();
