/**
 * @see {@link https://github.com/johnpapa/angular-styleguide/blob/master/a1/README.md#style-y270}
 * @license MIT
 */
(function() {
    'use strict';

    angular
        .module('shared.routes')
        .provider('routerHelper', RouterHelperProvider);

    RouterHelperProvider.$inject = ['$stateProvider', '$urlRouterProvider'];

    function RouterHelperProvider($stateProvider, $urlRouterProvider) {
        this.$get = RouterHelper;

        RouterHelper.$inject = ['$state'];

        function RouterHelper($state) {
            var hasOtherwise = false;

            var service = {
                configureRedirect: configureRedirect,
                configureStates: configureStates,
                getStates: getStates,
            };

            return service;

            function configureRedirect(from, to) {
                $urlRouterProvider.when(from, to);
            }

            function configureStates(states, otherwisePath) {
                states.forEach(function(state) {
                    $stateProvider.state(state.state, state.config);
                });
                if (otherwisePath && !hasOtherwise) {
                    hasOtherwise = true;
                    $urlRouterProvider.otherwise(otherwisePath);
                }
            }

            function getStates() {
                return $state.get();
            }
        }
    }
})();
