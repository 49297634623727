(function() {
    'use strict';

    angular
        .module('auth.signIn')
        .run(setupRoutes);

    setupRoutes.$inject = ['routerHelper', 'appRoot'];

    function setupRoutes(routerHelper, appRoot) {
        routerHelper.configureStates(getStates(appRoot), appRoot + '/sign-in');
    }

    function getStates(root) {
        return [
            {
                state: 'signIn',
                config: {
                    url: root + '/sign-in?{continue}',
                    controller: 'SignInController as vm',
                    templateUrl: 'templates/auth/sign-in/sign-in.html',
                    params: {
                        continue: {
                            squash: true,
                            value: null,
                        },
                    },
                },
            },
            {
                state: 'android',
                config: {
                    url: root + '/android?{token}',
                    templateUrl: 'templates/auth/sign-in/android.html',
                },
            },
        ];
    }
})();
