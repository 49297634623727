(function() {
    'use strict';

    angular
        .module('shared.routes')
        .provider('globalResolve', GlobalResolveProvider);

    GlobalResolveProvider.$inject = [
        '$stateProvider',
    ];

    function GlobalResolveProvider($stateProvider) {
        this.$get = _.noop;
        this.resolveGlobally = resolveGlobally;

        function resolveGlobally(id, resolver) {
            $stateProvider.decorator('globalResolveDecorator-' + id, function(state, parent) {
                var result;
                if (_.isFunction(parent)) {
                    result = parent(state);
                }

                if (!state.resolve) {
                    state.resolve = {};
                }

                state.resolve['globalResolve-' + id] = resolver;

                return result;
            });
        }
    }
})();
