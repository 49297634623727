(function() {
    'use strict';

    angular
        .module('shared.validators')
        .provider('emailValidatorService', EmailValidatorServiceProvider);

    function EmailValidatorServiceProvider() {
        this.$get = emailValidatorServiceFactory;

        emailValidatorServiceFactory.$inject = [
            '$q',
            'apiService',
        ];

        function emailValidatorServiceFactory() {
            var di = diHelper(emailValidatorServiceFactory.$inject, arguments);

            return new EmailValidatorService(di);
        }
    }

    function EmailValidatorService(di) {
        var checksInProgress = {};

        this.checkEmail = getCheck;

        function getCheck(email, userId) {
            if (checksInProgress[email]) {
                return checksInProgress[email];
            }

            var checkPromise = di.apiService.post({
                endpoint: ['auth', 'email', 'check'],
                data: {
                    email: email,
                    userId: userId,
                },
            });

            checksInProgress[email] = checkPromise;

            return checkPromise
                .finally(function() {
                    delete checksInProgress[email];
                });
        }
    }
})();
