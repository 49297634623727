(function() {
    'use strict';

    angular
        .module('auth.register')
        .run(setupRoutes);

    setupRoutes.$inject = ['routerHelper', 'appRoot'];

    function setupRoutes(routerHelper, appRoot) {
        routerHelper.configureStates(getStates(appRoot));
    }

    function getStates(root) {
        return [
            {
                state: 'register',
                config: {
                    url: root + '/register',
                    controller: 'RegisterController as vm',
                    templateUrl: 'templates/auth/register/register.html',
                },
            },
        ];
    }
})();
