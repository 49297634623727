(function() {
    'use strict';

    angular
        .module('shared.util')
        .provider('isbnService', IsbnServiceProvider);

    function IsbnServiceProvider() {
        this.$get = isbnServiceFactory;

        isbnServiceFactory.$inject = [
        ];

        function isbnServiceFactory() {
            var di = diHelper(isbnServiceFactory.$inject, arguments);

            return new IsbnService(di);
        }
    }

    function IsbnService() {
        var self = this;

        var ISBN_10_NON_DIGITS = /[^\dX]/g;
        var ISBN_13_NON_DIGITS = /[^\d]/g;

        this.calculateCheckDigit = function(isbn) {
            if (_.isNumber(isbn)) {
                isbn = '' + isbn;
            } else if (!_.isString(isbn)) {
                throw new TypeError('isbn must be a string');
            }

            isbn = isbn.replace(/[^\d]/g, '');

            var i;
            var checkDigit;
            var sum;

            if (isbn.length === 9) {
                sum = 0;
                for (i = 0; i < 9; ++i) {
                    sum += parseInt(isbn[i], 10) * (10 - i);
                }

                checkDigit = (11 - (sum % 11)) % 11;

                if (checkDigit === 10) {
                    checkDigit = 'X';
                }
            } else if (isbn.length === 12) {
                sum = 0;
                for (i = 0; i < 12; ++i) {
                    var multiplier = (i % 2 === 0 ? 1 : 3);
                    sum += parseInt(isbn[i], 10) * multiplier;
                }

                checkDigit = (10 - (sum % 10)) % 10;
            } else {
                throw new TypeError('unable to compute check digit for ISBN of length ' + isbn.length);
            }

            return checkDigit;
        };

        this.isValid = function(isbn) {
            // Make sure the value is normalized
            if (!_.isString(isbn)) {
                return false;
            }

            switch (isbn.length) {
                case 9:
                    return self.isValidIsbn9(isbn);
                case 10:
                    return self.isValidIsbn10(isbn);
                case 13:
                    return self.isValidIsbn13(isbn);
            }

            return false;
        };

        this.isValidIsbn9 = function(isbn) {
            if (!_.isString(isbn) || isbn.length !== 9) {
                return false;
            }

            return self.isValidIsbn10('0' + isbn);
        };

        this.isValidIsbn10 = function(isbn) {
            if (!_.isString(isbn)) {
                return false;
            }

            isbn = isbn.replace(ISBN_10_NON_DIGITS, '');

            if (isbn.length !== 10) {
                return false;
            }

            var sum = 0;
            for (var i = 0; i < 10; ++i) {
                var multiplier = 10 - i;
                var digit = isbn[i];
                if (digit === 'X') {
                    if (i !== 9) {
                        // 'X' must be the last character if present
                        return false;
                    }
                    digit = 10;
                } else {
                    digit = parseInt(digit, 10);
                }
                sum += digit * multiplier;
            }

            return (sum % 11 === 0);
        };

        this.isValidIsbn13 = function(isbn) {
            if (!_.isString(isbn)) {
                return false;
            }

            isbn = isbn.replace(ISBN_13_NON_DIGITS, '');

            if (isbn.length !== 13) {
                return false;
            }

            var i;

            // Valid ISBN-13 prefixes are 978 and 979X where X is between 1 and 9, inclusive.
            // 9790 is used for International Standard Music Numbers.
            var validPrefixes = ['978'];
            for (i = 1; i < 9; ++i) {
                validPrefixes.push('979' + i);
            }

            // Make sure the ISBN matches one of the valid prefixes
            var isPrefixValid = false;
            _.forEach(validPrefixes, function(prefix) {
                if (_.startsWith(isbn, prefix)) {
                    isPrefixValid = true;
                    return false; // Break forEach
                }

                return true;
            });

            if (!isPrefixValid) {
                return false;
            }

            var sum = 0;
            for (i = 0; i < 13; ++i) {
                var multiplier = ((i % 2 === 0) ? 1 : 3);
                sum += parseInt(isbn[i], 10) * multiplier;
            }

            return (sum % 10 === 0);
        };

        this.normalize = function(isbn) {
            if (_.isNumber(isbn)) {
                isbn = '' + isbn;
            } else if (!_.isString(isbn)) {
                throw new TypeError('isbn must be a string');
            }

            isbn = isbn.replace(ISBN_10_NON_DIGITS, '');

            if (!self.isValid(isbn)) {
                throw new TypeError('isbn is invalid');
            }

            if (isbn.length === 9) {
                isbn = '9780' + isbn.substr(0, 8);
                isbn += self.calculateCheckDigit(isbn);
            } else if (isbn.length === 10) {
                isbn = '978' + isbn.substr(0, 9);
                isbn += self.calculateCheckDigit(isbn);
            } else if (isbn.length !== 13) {
                throw new TypeError('bad ISBN length of ' + isbn.length);
            }

            return isbn;
        };

        this.validate = function(isbn) {
            isbn = self.normalize(isbn);
            if (!self.isValid(isbn)) {
                throw new TypeError('invalid isbn');
            }

            return isbn;
        };
    }
})();
