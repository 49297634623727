(function() {
    'use strict';

    angular
        .module('shared.validators')
        .directive('checkEmail', checkEmailDirective);

    checkEmailDirective.$inject = [
        '$parse',
        '$q',

        'emailValidatorService',
        'promiseDebounce',
    ];

    function checkEmailDirective() {
        var di = diHelper(checkEmailDirective.$inject, arguments);

        var directive = {
            link: _.partial(checkEmailLink, di),
            require: 'ngModel',
            restrict: 'A',
        };

        return directive;
    }

    function checkEmailLink(di, $scope, $element, $attrs, model) {
        var getUserId = _.constant(undefined);
        if (_.isString($attrs.checkEmailUserId)) {
            getUserId = _.partial(di.$parse($attrs.checkEmailUserId), $scope);
        }

        var isValueValid = _.constant(undefined);
        if (_.isString($attrs.checkEmailOverride)) {
            isValueValid = _.partial(di.$parse($attrs.checkEmailOverride), $scope);
        }

        var pendingChecks = {};

        var createCheck = di.promiseDebounce({
            wait: 500,
            callback: function(email) {
                var valid = isValueValid({
                    $value: email,
                });

                if (!_.isNil(valid)) {
                    return {
                        available: true,
                        valid: valid,
                    };
                }

                return di.emailValidatorService.checkEmail(email, getUserId());
            },
            cancel: function(defer) {
                defer.resolve();
            },
        });

        function getCheck(email) {
            if (pendingChecks[email]) {
                return pendingChecks[email];
            }

            var check = createCheck(email);
            pendingChecks[email] = check;

            return check
                .finally(function() {
                    if (pendingChecks[email] === check) {
                        delete pendingChecks[email];
                    }
                });
        }

        function runValidator(checkId, modelValue, viewValue) {
            if (model.$isEmpty(modelValue)) {
                return di.$q.resolve();
            }

            return getCheck(viewValue)
                .then(function(result) {
                    if (!result) {
                        return null;
                    }

                    var checkResult = _.get(result, [checkId]);
                    if (checkResult) {
                        return di.$q.resolve();
                    }

                    return di.$q.reject();
                });
        }

        function setupValidator(validatorName, checkId) {
            model.$asyncValidators[validatorName] = _.partial(runValidator, checkId);
        }

        setupValidator('emailValid', 'valid');
        setupValidator('emailAvailable', 'available');
    }
})();
