(function(jQuery, modernizr) {
    'use strict';

    // Features that must be supported by the browser in order for the app to
    // function in a basic manner
    var requiredFeatures = [
        'cookies',
        'cors',
        'es5array',
        'es5date',
        'es5function',
        'es5object',
        'es5string',
        'es5syntax',
        'es5undefined',
        'eventlistener',
        'history',
        'json',
        'unknownelements',
    ];

    // Features that are not required, but without them the UX is reduced.
    var recommendedFeatures = [
        'cssanimations',
        'csstransitions',
        'borderradius',
        'boxshadow',
        'fontface', // for Font-Awesome
        'inputtypes.number',
        'localstorage',
    ];

    function checkFeatures(featureSet) {
        var missing = [];
        for (var i = 0; i < featureSet.length; ++i) {
            var feature = featureSet[i];
            if (!_.get(modernizr, feature)) {
                missing.push(feature);
            }
        }

        return missing;
    }

    function checkRequiredFeatures() {
        return checkFeatures(requiredFeatures);
    }

    function checkRecommendedFeatures() {
        return checkFeatures(recommendedFeatures);
    }

    jQuery(function() {
        var htmlEle = jQuery('html');
        var missing = checkRequiredFeatures();

        if (missing.length) {
            htmlEle.addClass('modernizr-missing-required');
            return;
        }

        missing = checkRecommendedFeatures();

        if (missing.length) {
            htmlEle.addClass('modernizr-missing-recommended');
        }
    });
})(window.jQuery, window.Modernizr);
