(function() {
    'use strict';

    angular.module('auth.core', [
        'angular-loading-bar',
        'focus-if',
        'ngCookies',
        'ngMessages',
        'ui.bootstrap',
        'ui.router',
        'inline',
        'angularMoment',
        'shared.analytics',
        'shared.routes',
        'shared.api',
        'shared.features',
    ]);
})();
