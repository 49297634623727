/**
 * Copied and modified from
 * https://github.com/getsentry/raven-js/blob/94fde0c19bb030b280f184e9111e97668b23833e/src/raven.js
 */
(function() {
    'use strict';

    angular
        .module('shared.raven')
        .config(setupExceptionHandler);

    setupExceptionHandler.$inject = [
        '$provide',
    ];

    function setupExceptionHandler($provide) {
        $provide.decorator('$exceptionHandler', exceptionHandler);
    }

    exceptionHandler.$inject = [
        '$delegate',
        'Raven',
    ];

    function exceptionHandler($delegate, raven) {
        return function(ex, cause) {
            raven.captureException(ex, {
                extra: {
                    cause: cause,
                },
            });
            $delegate(ex, cause);
        };
    }
})();
