(function() {
    'use strict';

    angular
        .module('auth.confirmEmail')
        .run(setupRoutes);

    setupRoutes.$inject = ['routerHelper', 'appRoot'];

    function setupRoutes(routerHelper, appRoot) {
        routerHelper.configureStates(getStates(appRoot));
    }

    function getStates(root) {
        return [
            {
                state: 'confirmEmail',
                config: {
                    url: root + '/confirm-email/:token',
                    controller: 'ConfirmEmailController as vm',
                    templateUrl: 'templates/auth/confirm-email/confirm-email.html',
                },
            },
            {
                state: 'confirmEmail.complete',
                config: {
                    url: '/confirmed',
                    views: {
                        '@': {
                            controller: 'ConfirmEmailCompleteController as vm',
                            templateUrl: 'templates/auth/confirm-email/complete.html',
                        },
                    },
                },
            },
        ];
    }
})();
