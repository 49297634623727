(function() {
    'use strict';

    angular.module('auth', [
        'auth.core',
        'auth.clever',
        'auth.confirmEmail',
        'auth.mobile',
        'auth.register',
        'auth.resetPassword',
        'auth.signIn',
        'auth.user',
    ]);
})();
