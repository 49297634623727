(function() {
    'use strict';

    angular
        .module('shared.util')
        .factory('promiseDebounce', promiseDebounceFactory);

    promiseDebounceFactory.$inject = [
        '$timeout',
        '$q',
    ];

    function promiseDebounceFactory() {
        var di = diHelper(promiseDebounceFactory.$inject, arguments);

        return _.partial(promiseDebounceMaker, di);
    }

    function promiseDebounceMaker(di, options) {
        var $q = di.$q;
        var $timeout = di.$timeout;

        if (!options) {
            throw new TypeError('options must not be undefined');
        }

        options = _.clone(options);

        if (!_.isFinite(options.wait) || options.wait <= 0) {
            throw new TypeError('options.wait must be a positive number');
        }

        if (!_.isFunction(options.callback)) {
            throw new TypeError('options.callback must be function');
        }

        var pending = null;

        debouncer.cancel = cancel;

        reset(); // Set status variables

        return debouncer;

        function reset() {
            if (pending) {
                $timeout.cancel(pending.timeout);

                pending = null;
            }

            debouncer.pending = false;
            debouncer.pendingArguments = undefined;
        }

        function cancel() {
            if (pending && _.isFunction(options.cancel)) {
                options.cancel(pending.defer);
            }

            reset();
        }

        function debouncer() {
            cancel();

            var defer = $q.defer();
            var args = Array.prototype.slice.call(arguments);

            var timeout = $timeout(function() {
                defer.resolve(options.callback.apply(null, args));

                if (defer === _.get(pending, 'defer')) {
                    reset(); // Clear status variables
                }
            }, options.wait);

            pending = {
                defer: defer,
                timeout: timeout,
            };

            debouncer.pending = true;
            debouncer.pendingArguments = args;

            return defer.promise;
        }
    }
})();
