(function() {
    'use strict';

    angular
        .module('shared.features')
        .config(configureRuntimeFeatures);

    configureRuntimeFeatures.$inject = [
        '$windowProvider',
        'featureServiceProvider',
    ];

    function configureRuntimeFeatures() {
        var di = diHelper(configureRuntimeFeatures.$inject, arguments);

        var $window = di.$windowProvider.$get();

        _.forEach($window.FEATURES_ACTIVE, function(feature) {
            di.featureServiceProvider.setFeature(feature, true);
        });
    }
})();
