(function() {
    'use strict';

    angular
        .module('auth.signIn')
        .controller('SignInController', SignInController);

    SignInController.$inject = [
        '$log',
        '$stateParams',
        '$window',

        'apiService',
        'userService',
        'featureService',
        'urlService',
    ];

    function SignInController() {
        var vm = this;
        var di = diHelper(SignInController.$inject, arguments);

        di.userService.guestPage();

        activate();

        function activate() {
            di.$log.debug('activated sign in controller');

            vm.actionInProgress = false;
            vm.credentialsIncorrect = false;

            // Auto fill default credentials during development.
            if (di.featureService.isEnabled('autofillSigninCredentials')) {
                vm.email = 'admin@example.com';
                vm.password = 'password';
                vm.remember = true;
            }

            // Function bindings
            vm.canSignIn = canSignIn;
            vm.getCleverOAuthStateParams = getCleverOAuthStateParams;
            vm.goToApp = goToApp;
            vm.signIn = signIn;
            vm.showCleverButton = showCleverButton;
        }

        function canSignIn() {
            if (vm.actionInProgress) {
                return false;
            }

            if (!vm.signInForm || vm.signInForm.$invalid) {
                return false;
            }

            return true;
        }

        function getCleverOAuthStateParams() {
            return {
                continue: di.$stateParams.continue,
            };
        }

        function signIn() {
            if (!vm.canSignIn()) {
                return;
            }

            vm.actionInProgress = true;
            vm.accountDisabled = false;
            vm.credentialsIncorrect = false;

            di.apiService
                .post({
                    endpoint: ['auth', 'authenticate'],
                    data: {
                        email: vm.email,
                        password: vm.password,
                        remember: !!vm.remember,
                    },
                })
                .then(
                    function(result) {
                        var dest = result.destination;

                        vm.goToApp(dest);
                    },
                    function(response) {
                        vm.actionInProgress = false;

                        if (response.status === 403) {
                            if (_.get(response, 'data.error') === 'userDisabled') {
                                vm.accountDisabled = true;
                            } else {
                                vm.credentialsIncorrect = true;
                            }
                        }
                    }
                );
        }

        function goToApp(destination) {
            destination = destination || '/app';

            if (di.$stateParams.continue) {
                var parsedUrl = di.urlService.parse(di.$stateParams.continue);

                var destinationParts = [
                    parsedUrl.pathname,
                    parsedUrl.search,
                    parsedUrl.hash,
                ];

                destinationParts = _.filter(destinationParts);
                destination = destinationParts.join('');
            }

            di.$window.location = destination;
        }

        function showCleverButton() {
            return di.featureService.isEnabled('cleverSsoButton');
        }
    }
})();
