(function() {
    'use strict';

    angular
        .module('shared.validators')
        .directive('customValidator', customValidatorDirective);

    customValidatorDirective.$inject = [
        '$parse',
        '$q',
    ];

    function customValidatorDirective() {
        var di = diHelper(customValidatorDirective.$inject, arguments);

        var directive = {
            link: _.partial(customValidatorLink, di),
            require: 'ngModel',
            restrict: 'A',
        };

        return directive;
    }

    function customValidatorLink(di, $scope, $element, $attrs, model) {
        var validatorName = $attrs.customValidatorName || 'customValidator';

        var isAsync = _.has($attrs, 'customValidatorAsync');

        var validatorSet;
        if (isAsync) {
            validatorSet = model.$asyncValidators;
        } else {
            validatorSet = model.$validators;
        }

        validatorSet[validatorName] = customValidator;

        var validatorExpression = di.$parse($attrs.customValidator);

        function customValidator(modelValue, viewValue) {
            if (model.$isEmpty(modelValue)) {
                return di.$q.resolve();
            }

            var expressionResult = validatorExpression($scope, {
                $modelValue: modelValue,
                $value: viewValue,
                $viewValue: viewValue,
            });

            if (isAsync) {
                if (expressionResult && _.isFunction(expressionResult.then)) {
                    return expressionResult;
                }

                if (expressionResult) {
                    return di.$q.resolve();
                }

                return di.$q.reject();
            }

            return !!expressionResult;
        }
    }
})();
