(function() {
    'use strict';

    var SCRIPT_SOURCE = 'https://www.google-analytics.com/analytics.js';
    var SCRIPT_DEBUG_SOURCE = 'https://www.google-analytics.com/analytics_debug.js';

    angular
        .module('shared.analytics')
        .provider('googleAnalyticsService', GoogleAnalyticsServiceProvider);

    function GoogleAnalyticsServiceProvider() {
        var serviceOptions = {
            debugModeEnabled: false,
            enabled: true,
            globalObjectName: 'ga',
            propertyId: null,
        };

        this.setDebugMode = setDebugMode;
        this.setEnabled = setEnabled;
        this.setGlobalObjectName = setGlobalObjectName;
        this.setPropertyId = setPropertyId;
        this.$get = googleAnalyticsServiceFactory;

        function setDebugMode(debugModeEnabled) {
            serviceOptions.debugModeEnabled = !!debugModeEnabled;
        }

        function setEnabled(enabled) {
            if (enabled === undefined) {
                enabled = true;
            }

            serviceOptions.enabled = !!enabled;
        }

        function setGlobalObjectName(name) {
            if (!_.isString(name)) {
                throw new TypeError('name must be a string');
            }

            serviceOptions.globalObjectName = name;
        }

        function setPropertyId(propertyId) {
            if (!_.isString(propertyId)) {
                throw new TypeError('propertyId must be a string');
            }

            serviceOptions.propertyId = propertyId;
        }

        googleAnalyticsServiceFactory.$inject = [
            '$rootScope',
            '$state',
            '$window',
        ];

        function googleAnalyticsServiceFactory() {
            var di = diHelper(googleAnalyticsServiceFactory.$inject, arguments);

            return new GoogleAnalyticsService(di, serviceOptions);
        }
    }

    function GoogleAnalyticsService(di, options) {
        var self = this;

        this.initialize = function() {
            if (!options.propertyId) {
                options.enabled = false;
            }

            if (options.enabled) {
                runSetup();
            }
        };

        this.sendCommand = function gaServiceSendCommand() {
            if (!options.enabled) {
                return;
            }

            var args = Array.prototype.slice.call(arguments);

            di.$window[options.globalObjectName].apply(di.$window, args);
        };

        function createGaQueue() {
            var $window = di.$window;
            var gaObjectName = options.globalObjectName;

            $window.GoogleAnalyticsObject = options.globalObjectName;

            if (!$window[gaObjectName]) {
                $window[gaObjectName] = function() {
                    if (!$window[gaObjectName].q) {
                        $window[gaObjectName].q = [];
                    }

                    $window[gaObjectName].q.push(arguments);
                };

                $window[gaObjectName].l = 1 * new Date();
            }
        }

        function handleStateChange(event, state, stateParams) {
            // Normalize stateParams
            var normalizedParams = _.cloneDeep(stateParams);
            _.forEach(normalizedParams, function(value, name) {
                if (_.isNumber(value)) {
                    value = 1;
                } else if (_.isString(value)) {
                    value = name;
                } else if (_.isDate(value)) {
                    value = new Date(0);
                }

                normalizedParams[name] = value;
            });

            var href = di.$state.href(state, normalizedParams);
            var normalized = href;

            // Drop the query string
            var parts = normalized.split('?', 1);
            normalized = parts[0];

            self.sendCommand('set', 'page', normalized);
            self.sendCommand('send', 'pageview');
        }

        function loadAnalyticsScript() {
            var $document = di.$window.document;
            var scriptSource = options.debugModeEnabled ? SCRIPT_DEBUG_SOURCE : SCRIPT_SOURCE;

            var analyticsScript = $document.createElement('script');
            analyticsScript.async = true;
            analyticsScript.src = scriptSource;

            var firstScript = $document.getElementsByTagName('script')[0];
            firstScript.parentNode.insertBefore(analyticsScript, firstScript);
        }

        function runSetup() {
            createGaQueue();
            loadAnalyticsScript();

            self.sendCommand('create', options.propertyId, 'auto');

            di.$rootScope.$on('$stateChangeSuccess', handleStateChange);
        }
    }
})();
