(function() {
    'use strict';

    angular
        .module('shared.util')
        .provider('urlService', UrlServiceProvider);

    function UrlServiceProvider() {
        this.$get = urlServiceFactory;

        urlServiceFactory.$inject = [
            '$window',
        ];

        function urlServiceFactory() {
            var di = diHelper(urlServiceFactory.$inject, arguments);

            return new UrlService(di);
        }
    }

    function UrlService(di) {
        this.parse = function(url) {
            var element = di.$window.document.createElement('a');
            element.href = url;

            var fields = [
                'protocol',
                'hostname',
                'port',
                'pathname',
                'search',
                'hash',
                'host',
            ];

            var parsedUrl = {};

            _.forEach(fields, function(field) {
                parsedUrl[field] = element[field];
            });

            return parsedUrl;
        };
    }
})();
