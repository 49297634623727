(function() {
    'use strict';

    var RANDOM_CHARS = 'abcdefghijklmnopqrstuvwxyz';

    angular
        .module('shared.util')
        .directive('blockAutocomplete', blockAutocompleteDirective);

    function blockAutocompleteDirective() {
        var directive = {
            link: blockAutocompleteLink,
            restrict: 'A',
        };

        return directive;
    }

    function blockAutocompleteLink($scope, $element) {
        var element = $element.get(0);
        element.autocomplete = 'autocomplete-blocked-' + _.sample(RANDOM_CHARS, 8).join('');
    }
})();
