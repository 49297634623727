(function() {
    'use strict';

    angular
        .module('auth.clever')
        .controller('CleverController', CleverController);

    CleverController.$inject = [
        '$log',
        '$location',
        '$stateParams',
        '$state',
        '$window',

        'cleverOAuthService',
        'urlService',
    ];

    function CleverController() {
        var vm = this;
        var di = diHelper(CleverController.$inject, arguments);

        activate();

        function activate() {
            di.$log.debug('activated clever controller');

            vm.error = null;
            vm.stage = 'auth'; // auth, redirect, error
            vm.retryable = false;

            vm.canRetry = canRetry;
            vm.getRetryUrl = getRetryUrl;
            vm.showLoadingIndicator = showLoadingIndicator;
            vm.showMessage = showMessage;

            authenticate();
        }

        function authenticate() {
            vm.error = null;
            var code = di.$stateParams.code;
            var state = di.$stateParams.state;
            var platform = di.$stateParams.platform;
            var continueUrl = di.$stateParams.continue;

            var authOptions = {
                code: code,
                continue: continueUrl,
                platform: platform,
                redirectUri: getCurrentUrl(),
                state: state,
            };

            di.cleverOAuthService.callAuthenticate(authOptions)
                .then(function(response) {
                    return handleResponse(response);
                })
                .catch(function(response) {
                    var data = _.get(response, 'data');

                    if (_.isObject(data)) {
                        return handleResponse(data);
                    }

                    return handleResponse({
                        success: false,
                    });
                });
        }

        function canRetry() {
            return vm.retryable;
        }

        function getCurrentUrl() {
            var loc = di.$location;

            var url = loc.absUrl();
            var queryStartIndex = url.indexOf('?');
            url = url.slice(0, queryStartIndex);

            return url;
        }

        function getRetryUrl() {
            return vm.retryUrl || di.$state.href('clever');
        }

        function goTo(url) {
            di.$window.location = url;
        }

        function goToApp(destination, continueUrl) {
            destination = destination || '/app';

            if (continueUrl) {
                var parsedUrl = di.urlService.parse(continueUrl);

                var destinationParts = [
                    parsedUrl.pathname,
                    parsedUrl.search,
                    parsedUrl.hash,
                ];

                destinationParts = _.filter(destinationParts);
                destination = destinationParts.join('');
            }

            di.$window.location = destination;
        }

        function handleResponse(data) {
            if (data.success) {
                vm.message = null;
            } else {
                vm.message = data.reason || 'An error occurred while signing in.';
            }

            if (_.isString(data.redirect)) {
                vm.stage = 'redirect';
                vm.retryable = false;

                if (data.success) {
                    goToApp(data.redirect, data.continue);
                } else {
                    goTo(data.redirect);
                }
            } else {
                vm.stage = 'error';
                vm.retryable = _.get(data, 'retry', false);
            }

            vm.retryUrl = data.retryUrl || null;
        }

        function showLoadingIndicator() {
            return _.includes(['auth', 'redirect'], vm.stage);
        }

        function showMessage() {
            return vm.stage === 'error';
        }
    }
})();
