(function() {
    'use strict';

    angular
        .module('shared.util')
        .provider('cleverService', CleverServiceProvider);

    function CleverServiceProvider() {
        this.$get = cleverServiceFactory;

        cleverServiceFactory.$inject = [

        ];

        function cleverServiceFactory() {
            var di = diHelper(cleverServiceFactory.$inject, arguments);

            return new CleverService(di);
        }
    }

    function CleverService() {
        this.getDefaultEmailDomains = getDefaultEmailDomains;
        this.isDefaultEmail = isDefaultEmail;

        function getDefaultEmailDomains() {
            // From App\Services\EmailService::getDefaultCleverEmailDomains
            return [
                'bookretriever-clever.com',
            ];
        }

        function isDefaultEmail(email) {
            var domains = getDefaultEmailDomains();

            return _.some(domains, function(domain) {
                return _.endsWith(email, '@' + domain);
            });
        }
    }
})();
