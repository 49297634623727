(function() {
    'use strict';

    angular
        .module('shared.util')
        .directive('brLoadingOverlay', overlayDirective);

    function overlayDirective() {
        var directive = {
            controller: OverlayController,
            restrict: 'A',
            scope: false, // We don't need an additional scope for this directive
        };

        return directive;
    }

    OverlayController.$inject = [
        '$animate',
        '$element',
        '$q',
        '$log',

        'loadingOverlayService',
    ];

    function OverlayController() {
        var vm = this;
        var di = diHelper(OverlayController.$inject, arguments);

        var $animate = di.$animate;
        var $element = di.$element;
        var $q = di.$q;
        var loadingOverlayService = di.loadingOverlayService;

        var transitionPromise = null;
        var transitionDestination = false; // false = hidden; true = shown

        vm.$onInit = activate;
        vm.$onDestroy = deactivate;

        function activate() {
            di.$log.debug('activate loading overlay directive controller');

            vm.hide = hide;
            vm.show = show;

            loadingOverlayService.setOverlay(vm);
        }

        function deactivate() {
            loadingOverlayService.setOverlay(null);
        }

        function show(fast) {
            return change(true, fast);
        }

        function hide(fast) {
            return change(false, fast);
        }

        function change(showOverlay, fast) {
            if (transitionPromise) {
                if (showOverlay === transitionDestination) {
                    if (!fast) {
                        return transitionPromise;
                    }
                }

                // Cancel animation
                $animate.cancel(transitionPromise);
            } else if (showOverlay === transitionDestination) {
                // If we are already in the desired state, there is no need to do extra work
                return $q.resolve();
            }

            var method = showOverlay ? 'removeClass' : 'addClass';
            var CSS_CLASS = 'ng-hide';

            transitionDestination = showOverlay;

            if (fast) {
                $element[method](CSS_CLASS);
                transitionPromise = null;

                return $q.resolve();
            }

            var promise = $animate[method]($element, CSS_CLASS, {
                tempClasses: 'ng-hide-animate',
            });
            transitionPromise = promise;

            promise.finally(function() {
                if (transitionPromise === promise) {
                    transitionPromise = null;
                }
            });

            return promise;
        }
    }
})();
