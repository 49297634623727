(function() {
    'use strict';

    angular
        .module('shared.features')
        .provider('featureService', FeatureServiceProvider);

    function FeatureServiceProvider() {
        var additionalFeatures = {};

        this.setFeature = function(feature, enabled) {
            additionalFeatures[feature] = !!enabled;
        };

        this.$get = featureServiceFactory;

        featureServiceFactory.$inject = [

        ];

        function featureServiceFactory() {
            var di = diHelper(featureServiceFactory.$inject, arguments);

            di.additionalFeatures = additionalFeatures;

            return new FeatureService(di);
        }
    }

    function FeatureService(di) {
        var self = this;

        var featureMap = {};

        _.forEach(di.featureDefinitions, function(enabled, feature) {
            featureMap[feature] = enabled;
        });

        _.forEach(di.additionalFeatures, function(enabled, feature) {
            featureMap[feature] = enabled;
        });

        this.isEnabled = function(feature) {
            if (self.isKnown(feature)) {
                return !!featureMap[feature];
            }

            return false;
        };

        this.isDisabled = function(feature) {
            return !self.isEnabled(feature);
        };

        this.isKnown = function(feature) {
            return _.has(featureMap, feature);
        };
    }
})();
