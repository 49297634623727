(function() {
    'use strict';

    angular
        .module('shared.util')
        .run(watchForTimeOffset);

    watchForTimeOffset.$inject = [
        '$rootScope',
        '$window',
    ];

    function watchForTimeOffset() {
        var di = diHelper(watchForTimeOffset.$inject, arguments);

        // Offset from server time in milliseconds
        var timeOffset = 0;
        var oldNow = di.$window.moment.now;

        di.$window.moment.now = function() {
            var currentTime = oldNow.call(this);

            return currentTime + timeOffset;
        };

        di.$rootScope.$on('apiServiceRequestSuccessful', function(event, response) {
            if (response.headers('X-Server-Time')) {
                var serverTime = parseInt(response.headers('X-Server-Time'), 10);
                var newOffset = (serverTime * 1000) - oldNow();

                timeOffset = newOffset;
            }
        });
    }
})();
