(function() {
    'use strict';

    angular
        .module('auth.clever')
        .run(setupRoutes);

    setupRoutes.$inject = ['routerHelper', 'appRoot'];

    function setupRoutes(routerHelper, appRoot) {
        routerHelper.configureStates(getStates(appRoot));
    }

    function getStates(root) {
        return [
            {
                state: 'clever',
                config: {
                    url: root + '/clever',
                    abstract: true,
                    templateUrl: 'templates/auth/clever/root.html',
                },
            },
            {
                state: 'clever.oauth',
                config: {
                    url: '?{code}&{continue}&{platform}&{state}',
                    controller: 'CleverController as vm',
                    templateUrl: 'templates/auth/clever/clever.html',
                    params: {
                        code: {
                            value: null,
                        },
                        continue: {
                            value: null,
                        },
                        platform: {
                            value: null,
                        },
                        state: {
                            value: null,
                        },
                    },
                },
            },
            {
                state: 'clever.choose',
                config: {
                    url: '/choose?*',
                    controller: 'CleverChooseController as vm',
                    templateUrl: 'templates/auth/clever/choose.html',
                },
            },
            {
                state: 'clever.android',
                config: {
                    url: '/android?{token}',
                    templateUrl: 'templates/auth/clever/android.html',
                },
            },
        ];
    }
})();
