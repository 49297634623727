/**
 * Copied and modified from
 * https://github.com/getsentry/raven-js/blob/94fde0c19bb030b280f184e9111e97668b23833e/src/raven.js
 */
(function() {
    'use strict';

    angular
        .module('shared.raven')
        .provider('Raven', RavenProvider);

    function RavenProvider() {
        this.$get = getRaven;

        getRaven.$inject = [
            '$window',
        ];

        function getRaven($window) {
            return $window.Raven;
        }
    }
})();
