(function() {
    'use strict';

    angular
        .module('shared.passwords')
        .provider('passwordStrengthService', PasswordStrengthServiceProvider);

    function PasswordStrengthServiceProvider() {
        this.$get = passwordStrengthServiceFactory;

        passwordStrengthServiceFactory.$inject = [
            '$rootScope',
        ];

        function passwordStrengthServiceFactory() {
            var di = diHelper(passwordStrengthServiceFactory.$inject, arguments);

            return new PasswordStrengthService(di);
        }
    }

    function PasswordStrengthService(di) {
        var self = this;

        var lastPassword = null;
        var lastPasswordComplexity = null;

        // Make sure to clear the password cache every digest loop;
        di.$rootScope.$watch(function() {
            lastPassword = null;
            lastPasswordComplexity = null;
        });

        this.calculateComplexity = function(password) {
            if (!password) {
                return null;
            }

            if (lastPassword !== null && password === lastPassword) {
                return lastPasswordComplexity;
            }

            var complexity = getComplexity(password);

            lastPassword = password;
            lastPasswordComplexity = complexity;

            return complexity;
        };

        this.calculateIsStrong = function(password) {
            var score = self.calculateScore(password);

            return score && score >= 3;
        };

        this.calculateScore = function(password) {
            var complexity = self.calculateComplexity(password);

            if (complexity) {
                return complexity.score;
            }

            return null;
        };

        this.isZxcvbnReady = function() {
            return _.isFunction(window.zxcvbn);
        };

        function getComplexity(password) {
            if (!password) {
                return null;
            }

            if (!self.isZxcvbnReady()) {
                // Return 'unknown' complexity if we don't have zxcvbn yet.
                // Maybe we should return a quick complexity check instead?
                return null;
            }

            return window.zxcvbn(password);
        }
    }
})();
