(function() {
    'use strict';

    angular
        .module('auth.resetPassword')
        .controller('ResetPasswordController', ResetPasswordController);

    ResetPasswordController.$inject = [
        '$log',
        '$window',
        '$stateParams',
        'apiService',
    ];

    function ResetPasswordController() {
        var vm = this;
        var di = diHelper(ResetPasswordController.$inject, arguments);

        activate();

        function activate() {
            di.$log.debug('activated reset password controller');

            vm.actionInProgress = false;
            vm.checkingToken = false;
            vm.invalid = false;
            vm.token = di.$stateParams.token;

            // Function bindings
            vm.canResetPassword = canResetPassword;
            vm.checkToken = checkToken;
            vm.goToApp = goToApp;
            vm.resetPassword = resetPassword;

            vm.checkToken();
        }

        function canResetPassword() {
            if (!vm.passwordForm || vm.passwordForm.$invalid) {
                return false;
            }

            if (vm.actionInProgress) {
                return false;
            }

            if (!vm.passwordStrongEnough) {
                return false;
            }

            return true;
        }

        function checkToken() {
            vm.checkingToken = true;

            di.apiService
                .get({
                    endpoint: ['auth', 'password', 'reset', vm.token],
                })
                .then(
                    function(response) {
                        vm.email = response.email;
                    },
                    function(reason) {
                        if (reason.status === 404) {
                            vm.invalid = true;
                        }
                    }
                )
                .finally(function() {
                    vm.checkingToken = false;
                });
        }

        function resetPassword() {
            if (!vm.canResetPassword()) {
                return;
            }

            vm.actionInProgress = true;

            di.apiService
                .post({
                    endpoint: ['auth', 'password', 'reset', vm.token],
                    data: {
                        password: vm.newPassword,
                    },
                })
                .then(
                    function() {
                        vm.goToApp();
                    },
                    function(reason) {
                        vm.actionInProgress = false;
                        vm.invalid = false;
                        vm.newPassword = '';

                        if (reason.status === 404) {
                            vm.invalid = true;
                        } else if (reason.status === 400) {
                            vm.badPassword = true;
                        }
                    }
                );
        }

        function goToApp() {
            di.$window.location = '/app';
        }
    }
})();
