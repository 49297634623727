(function() {
    'use strict';

    angular
        .module('auth.mobile')
        .run(setupRoutes);

    setupRoutes.$inject = ['routerHelper', 'appRoot'];

    function setupRoutes(routerHelper, appRoot) {
        routerHelper.configureStates(getStates(appRoot));
    }

    function getStates(root) {
        return [
            {
                state: 'mobile',
                config: {
                    url: root,
                    abstract: true,
                    templateUrl: 'templates/auth/mobile/root.html',
                },
            },
            {
                state: 'mobile.android',
                config: {
                    url: '/android?{token}',
                    controller: 'MobileAndroidController as vm',
                    templateUrl: 'templates/auth/mobile/android.html',
                },
            },
            {
                state: 'mobile.ios',
                config: {
                    url: '/ios?{token}',
                    controller: 'MobileIOSController as vm',
                    templateUrl: 'templates/auth/mobile/ios.html',
                },
            },
        ];
    }
})();
