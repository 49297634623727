(function() {
    'use strict';

    angular
        .module('shared.raven')
        .provider('ravenActionTracker', RavenActionTrackerProvider);

    function RavenActionTrackerProvider() {
        var trackerOptions = {
            historyLimit: 30,
        };

        this.$get = ravenActionTrackerFactory;

        ravenActionTrackerFactory.$inject = [
            '$rootScope',

            'Raven',
        ];

        function ravenActionTrackerFactory() {
            var di = diHelper(ravenActionTrackerFactory.$inject, arguments);

            return new RavenActionTrackerService(di, trackerOptions);
        }
    }

    function RavenActionTrackerService(di, options) {
        // FIFO queue. New entries unshifted to front. Old entries popped off end.
        var actionHistory = [];
        var initialized = false;

        this.init = init;
        this.track = track;

        function compressHistory() {
            if (actionHistory.length > options.historyLimit) {
                actionHistory.pop();
            }
        }

        function historyUpdated() {
            di.Raven.setExtraContext({
                actionHistory: actionHistory,
            });
        }

        function init() {
            if (initialized) {
                return;
            }

            initialized = true;

            di.$rootScope.$on('$stateChangeSuccess', function(event, state, stateParams) {
                track('stateChange', {
                    state: state.name,
                    params: stateParams,
                });
            });
        }

        function track(name, details) {
            details = _.assign({}, details, {
                action: name,
            });

            details = _.cloneDeep(details);

            actionHistory.unshift(details);

            compressHistory();
            historyUpdated();
        }
    }
})();
