(function() {
    'use strict';

    angular
        .module('auth.resetPassword')
        .run(setupRoutes);

    setupRoutes.$inject = ['routerHelper', 'appRoot'];

    function setupRoutes(routerHelper, appRoot) {
        routerHelper.configureStates(getStates(appRoot));
    }

    function getStates(root) {
        return [
            {
                state: 'forgotPassword',
                config: {
                    url: root + '/forgot-password',
                    controller: 'ForgotPasswordController as vm',
                    templateUrl: 'templates/auth/reset-password/forgot-password.html',
                },
            },
            {
                state: 'resetPassword',
                config: {
                    url: root + '/reset-password/:token',
                    controller: 'ResetPasswordController as vm',
                    templateUrl: 'templates/auth/reset-password/reset-password.html',
                },
            },
        ];
    }
})();
