(function() {
    'use strict';

    angular
        .module('auth.confirmEmail')
        .controller('ConfirmEmailCompleteController', ConfirmEmailCompleteController);

    ConfirmEmailCompleteController.$inject = [
        '$log',
        '$state',
        '$stateParams',
        '$window',
    ];

    function ConfirmEmailCompleteController() {
        var vm = this;
        var di = diHelper(ConfirmEmailCompleteController.$inject, arguments);

        activate();

        function activate() {
            di.$log.debug('activated confirm email complete controller');

            // Function bindings
            vm.goToApp = goToApp;
        }

        function goToApp() {
            di.$window.location = '/app';
        }
    }
})();
