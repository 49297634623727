/**
 * Boots Angular on page load.
 * Done here instead of in an ngApp directive because it would be difficult to
 * add the directive into the parent template properly.
 */
(function(document) {
    'use strict';

    jQuery(document).ready(onDocumentReady);

    function onDocumentReady() {
        angular.bootstrap(document, ['auth'], {
            strictDi: true,
        });
    }
})(window.document);
