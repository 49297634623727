(function() {
    'use strict';

    angular
        .module('auth.clever')
        .provider('cleverOAuthService', CleverOAuthServiceProvider);

    function CleverOAuthServiceProvider() {
        this.$get = cleverOAuthServiceFactory;

        cleverOAuthServiceFactory.$inject = [
            'apiService',
        ];

        function cleverOAuthServiceFactory() {
            var di = diHelper(cleverOAuthServiceFactory.$inject, arguments);

            return new CleverOAuthService(di);
        }
    }

    function CleverOAuthService(di) {
        this.callAuthenticate = callAuthenticate;

        /**
         * @param {Object} options The data to provide to the request
         * @param {string} options.redirectUri The redirect URI to provide to Clever
         * @param {string} options.code The code received from Clever
         * @param {string} options.platform The mobile platform. Either 'android' or 'ios'.
         * @param {string} options.state The opaque state parameter to store details about this OAuth request.
         *
         * @returns {Promise} The request promise
         */
        function callAuthenticate(options) {
            var response = di.apiService.post({
                endpoint: ['auth', 'authenticate', 'clever'],
                data: options,
            });

            return response;
        }
    }
})();
