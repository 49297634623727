/**
 * Copied and modified from
 * https://github.com/getsentry/raven-js/blob/94fde0c19bb030b280f184e9111e97668b23833e/src/raven.js
 */
(function() {
    'use strict';

    var ISBN_VALUE_PATTERN = /(^|\D)(97[89])(\d{10})(\D|$)/g;
    var ANGULAR_PATTERN = /^\[((?:[$a-zA-Z0-9]+:)?(?:[$a-zA-Z0-9]+))] (.+?)\n(\S+)$/;

    angular
        .module('shared.raven')
        .run(setupMutator);

    setupMutator.$inject = [
        'Raven',

        'ravenActionTracker',
    ];

    function setupMutator(raven, ravenActionTracker) {
        raven.setDataCallback(mutateData);
        ravenActionTracker.init();
    }

    function mutateData(data) {
        mutateIsbn(data);
        mutateException(data);
    }

    function mutateException(data) {
        // We only care about mutating an exception
        var exception = data.exception;
        if (exception) {
            exception = exception.values[0];

            var matches = ANGULAR_PATTERN.exec(exception.value);
            if (matches) {
                // This type now becomes something like: $rootScope:inprog
                exception.type = matches[1];
                exception.value = matches[2];
                data.message = exception.type + ': ' + exception.value;

                // auto set a new tag specifically for the angular error url
                data.extra.angularDocs = matches[3].substr(0, 250);
            }
        }
    }

    // Recursively search for ISBN values and modify to avoid sentry data scrubbing
    function mutateIsbn(data) {
        _.forEach(data, function(value, key) {
            if (_.isString(value)) {
                data[key] = value.replace(ISBN_VALUE_PATTERN, '$1$2.$3$4');
            } else if (_.isObject(value)) {
                mutateIsbn(value);
            }
        });
    }
})();
