(function() {
    'use strict';

    angular
        .module('shared.api')
        .provider('bootstrapService', BootstrapServiceProvider);

    function BootstrapServiceProvider() {
        this.$get = bootstrapServiceFactory;
        this.addBootstraper = addBootstraper;
        this.addBootstrapKeys = addBootstrapKeys;
        this.addBootstrapHandler = addBootstrapHandler;

        var keys = [];
        var handlers = [];

        function addBootstraper(newKeys, handler) {
            addBootstrapHandler(handler);
            addBootstrapKeys(newKeys);
        }

        function addBootstrapKeys(newKeys) {
            if (_.isArray(newKeys)) {
                keys = _.union(keys, newKeys);
            } else {
                keys.push(newKeys);
                keys = _.uniq(keys);
            }
        }

        function addBootstrapHandler(handler) {
            if (!_.isFunction(handler)) {
                throw new TypeError('handler must be a function');
            }

            handlers.push(handler);
        }

        bootstrapServiceFactory.$inject = [
            '$injector',
            '$q',

            'apiService',
        ];

        function bootstrapServiceFactory() {
            var di = diHelper(bootstrapServiceFactory.$inject, arguments);

            return new BootstrapService(di, keys, handlers);
        }
    }

    function BootstrapService(di, bootstrapKeys, handlers) {
        var $q = di.$q;

        var isBootstrapped = false;
        var bootstrapAction = null;

        this.bootstrap = bootstrap;
        this.bootstrapFetch = bootstrapFetch;
        this.bootstrapWithData = bootstrapWithData;

        function bootstrap() {
            if (isBootstrapped) {
                return $q.resolve();
            } else if (bootstrapAction) {
                return bootstrapAction;
            }

            var keys = _.uniq(bootstrapKeys);

            if (!_.size(keys)) {
                isBootstrapped = true;

                return $q.resolve();
            }

            var promises = _.map(keys, function(key) {
                return bootstrapData(key)
                    .then(bootstrapWithData);
            });

            bootstrapAction = $q.all(promises);

            return bootstrapAction
                .then(function() {
                    isBootstrapped = true;
                })
                .finally(function() {
                    bootstrapAction = null;
                });
        }

        function bootstrapFetch(keys, libraryId) {
            var promises = _.map(keys, function(key) {
                return bootstrapData(key, libraryId);
            });

            return $q.all(promises)
                .then(function(results) {
                    var data = {};
                    _.forEach(results, function(result) {
                        data = _.assign(data, result);
                    });

                    return data;
                });
        }

        function bootstrapWithData(data) {
            _.forEach(handlers, function(handler) {
                di.$injector.invoke(handler, undefined, {
                    data: data,
                });
            });
        }

        function bootstrapData(dataKey, libraryId) {
            var data = [];

            if (libraryId) {
                data = {
                    libraryId: libraryId,
                };
            }

            data.timezoneOffset = new Date().getTimezoneOffset();

            var promise = di.apiService.get({
                endpoint: ['bootstrap', dataKey],
                data: data,
            });

            return promise;
        }
    }
})();
