(function(){
angular.module("auth").run(["$templateCache", function($templateCache) {
  $templateCache.put("templates/auth/clever/choose.html",
    "<div> Pick which server you want to auth to with Clever: </div> <ul> <li ng-repeat=\"option in vm.options track by option.domain\"> <a ng-href=\"{{option.url}}\" ng-bind=\"option.name\"></a> (<span ng-bind=\"option.domain\"></span>) </li> </ul> ");
  $templateCache.put("templates/auth/clever/clever.html",
    "<div class=\"loading-indicator\" ng-show=\"vm.showLoadingIndicator()\"> <i class=\"fa fa-spinner fa-3x fa-spin\"></i> </div> <div ng-show=\"vm.showMessage()\"> {{vm.message}} </div> <div ng-show=\"vm.canRetry()\"> <a class=\"submit-button\" ng-href=\"{{vm.getRetryUrl()}}\" target=\"_self\" n> Retry </a> </div> <div class=\"alternate-flow\" ng-hide=\"vm.showLoadingIndicator()\"> <p> Remember your Book Retriever password? <a ui-sref=\"signIn\">Sign In!</a> </p> </div> ");
  $templateCache.put("templates/auth/clever/root.html",
    "<ui-view class=\"page-clever\"></ui-view> ");
  $templateCache.put("templates/auth/confirm-email/complete.html",
    "<div> <p> You have confirmed your email address. </p> <button class=\"submit-button\" ng-click=\"vm.goToApp()\"> Go to Book Retriever </button> </div> ");
  $templateCache.put("templates/auth/confirm-email/confirm-email.html",
    "<div ng-show=\"vm.actionInProgress\"> <i class=\"fa fa-spinner fa-spin fa-3x\"></i> </div> <div ng-hide=\"vm.actionInProgress\"> <div ng-if=\"!(vm.complete || vm.invalid)\"> <div ng-if=\"vm.needsPassword\" ng-inline=\"templates/auth/confirm-email/needs-password.html\"></div> </div> <div ng-if=\"vm.invalid\" ng-inline=\"templates/auth/confirm-email/invalid.html\"></div> <div ng-if=\"vm.complete\" ng-inline=\"templates/auth/confirm-email/complete.html\"></div> </div> ");
  $templateCache.put("templates/auth/confirm-email/invalid.html",
    "<p> This email confirmation link seems to be invalid. It might have already been used, or it might have expired. If you need, you may <a ui-sref=\"forgotPassword\">reset your password</a>. </p> ");
  $templateCache.put("templates/auth/confirm-email/needs-password.html",
    "<form name=\"passwordForm\" novalidate ng-submit=\"vm.setPassword()\"> <h3> Almost done! </h3> <p> Please create a password for your Book Retriever account for <b>{{vm.email}}</b>. </p> <div class=\"input-row\"> <label class=\"if-no-placeholder\" for=\"password\">New password</label> <input type=\"password\" placeholder=\"New password\" id=\"newPassword\" required focus-if ng-model=\"vm.newPassword\" ng-disabled=\"vm.actionInProgress\"> <tuva-password-strength-meter password=\"vm.newPassword\" strong-enough=\"vm.passwordStrongEnough\"></tuva-password-strength-meter> </div> <button type=\"submit\" class=\"submit-button\" ng-disabled=\"!vm.canSetPassword()\"> Set Password </button> </form> ");
  $templateCache.put("templates/auth/mobile/android.html",
    "<div> You must install the <a href=\"https://play.google.com/store/apps/details?id=com.bookretriever.BookRetrieverScanningApp\"> Book Retriever Scanning App </a> </div> <div class=\"mobile-store-badge\"> <a href=\"https://play.google.com/store/apps/details?id=com.bookretriever.BookRetrieverScanningApp\"> <img alt=\"Get it on Google Play\" ng-src=\"/bust-{{CACHE_BUSTER}}/images/google-play-badge.png\"> </a> </div> <div class=\"trademark-notice\"> <p>Google Play and the Google Play logo are trademarks of Google Inc.</p> </div> ");
  $templateCache.put("templates/auth/mobile/ios.html",
    "<div> You must install the <a href=\"https://itunes.apple.com/us/app/book-retriever-scanning-app/id1086127313\"> Book Retriever Scanning App </a> </div> <div class=\"mobile-store-badge\"> <a href=\"https://itunes.apple.com/us/app/book-retriever-scanning-app/id1086127313\"> <img alt=\"Download on the App Store\" ng-src=\"/bust-{{CACHE_BUSTER}}/images/ios-itunes-badge.svg\"> </a> </div> <div class=\"trademark-notice\"> <p> Apple and the Apple logo are trademarks of Apple Inc., registered in the U.S. and other countries. App Store is a service mark of Apple Inc., registered in the U.S. and other countries. </p> </div> ");
  $templateCache.put("templates/auth/mobile/root.html",
    "<ui-view class=\"page-mobile\"></ui-view> ");
  $templateCache.put("templates/auth/register/register.html",
    "<form name=\"vm.registerForm\" novalidate ng-submit=\"vm.register()\"> <p> We just need your email address for now. We'll ask you for a new password later. </p> <div class=\"input-row\"> <label class=\"iff-no-placeholder\" for=\"email\">Email Address</label> <input type=\"email\" name=\"email\" id=\"email\" placeholder=\"email address\" check-email required ng-model=\"vm.email\" ng-change=\"vm.updateEmail('test')\"> </div> <ul ng-messages=\"vm.registerForm.email.$error\" ng-show=\"vm.registerForm.email.$touched &amp;&amp; vm.registerForm.email.$invalid\"> <li ng-message=\"email, emailValid\"> This email does not appear to be valid. </li> <li ng-message=\"emailAvailable\"> This email is already being used by another user. </li> </ul> <div class=\"input-row\"> <label class=\"iff-no-placeholder\" for=\"emailConfirmation\">Confirm Email Address</label> <input type=\"email\" name=\"emailConfirmation\" id=\"emailConfirmation\" placeholder=\"confirm email address\" require-matches=\"{{ vm.email }}\" required ng-model=\"vm.emailConfirmation\" ng-change=\"vm.updateEmailConfirm('test')\"> </div> <ul ng-messages=\"vm.registerForm.emailConfirmation.$error\" ng-show=\"vm.email != vm.emailConfirmation\"> <li ng-message=\"matches\"> Both emails must match. </li> </ul> <button type=\"submit\" class=\"submit-button\" ng-disabled=\"!vm.canRegister()\"> {{ vm.actionInProgress === \"register\" ? \"Registering...\" : \"Register\" }} </button> </form> <div class=\"alternate-flow\"> <p>Already have an account? <a ui-sref=\"signIn\">Sign In!</a></p> </div> ");
  $templateCache.put("templates/auth/reset-password/forgot-password.html",
    "<form name=\"vm.resetForm\" novalidate ng-submit=\"vm.resetPassword()\" ng-hide=\"vm.sent\"> <p> Let us know what your E-Mail address is, and we will send you a password reset link. If you can't remember what E-Mail address is, let us know at <a href=\"mailto:support@bookretriever.com\">support@bookretriever.com</a>. </p> <div class=\"input-row\"> <label class=\"if-no-placeholder\" for=\"email\">Email Address</label> <input type=\"email\" name=\"email\" id=\"email\" placeholder=\"email address\" required focus-if ng-model=\"vm.email\" ng-disabled=\"vm.actionInProgress\"> </div> <ul ng-messages=\"vm.emailErrors\" ng-show=\"vm.hasErrors()\"> <li ng-message=\"email\"> This email does not appear to be valid. </li> </ul> <button type=\"submit\" class=\"submit-button\" ng-disabled=\"!vm.canReset()\"> <span ng-show=\"vm.actionInProgress\"> Requesting reset... </span> <span ng-hide=\"vm.actionInProgress\"> Request a password reset </span> </button> <div class=\"alternate-flow\"> <p>Remember your password? <a ui-sref=\"signIn\">Sign In!</a></p> </div> </form> <div ng-show=\"vm.sent\"> We have sent an email to <strong ng-bind=\"vm.email\"></strong> with further instructions on resetting your password. </div> ");
  $templateCache.put("templates/auth/reset-password/reset-password.html",
    "<div ng-show=\"vm.checkingToken\"> <i class=\"fa fa-spinner fa-spin fa-3x\"></i> </div> <form name=\"vm.passwordForm\" novalidate ng-submit=\"vm.resetPassword()\" ng-hide=\"vm.checkingToken || vm.invalid\"> <p> Please select a new password for your Book Retriever account (<b>{{vm.email}}</b>). </p> <div class=\"input-row\"> <label class=\"if-no-placeholder\" for=\"password\">New password</label> <input type=\"password\" placeholder=\"New password\" id=\"newPassword\" focus-if=\"!(vm.checkingToken || vm.invalid)\" ng-model=\"vm.newPassword\" ng-disabled=\"vm.actionInProgress\" required> <tuva-password-strength-meter password=\"vm.newPassword\" strong-enough=\"vm.passwordStrongEnough\"></tuva-password-strength-meter> </div> <ul ng-show=\"vm.badPassword\"> <li> This password is not strong enough. </li> </ul> <button type=\"submit\" class=\"submit-button\" ng-disabled=\"!vm.canResetPassword()\"> Reset Password </button> </form> <div ng-show=\"vm.invalid\"> <p> The password reset link seems to no longer be valid It might have already been used, or it might have expired. If you need, you may try <a ui-sref=\"forgotPassword\">resetting your password</a> again. </p> <div class=\"alternate-flow\"> <p>Remember your password? <a ui-sref=\"signIn\">Sign In!</a></p> </div> </div> ");
  $templateCache.put("templates/auth/sign-in/sign-in.html",
    "<form name=\"vm.signInForm\" ng-submit=\"vm.signIn()\" novalidate> <div class=\"input-row\"> <label class=\"if-no-placeholder\" for=\"email\">Email Address</label> <input type=\"email\" placeholder=\"email address\" name=\"email\" required ng-model=\"vm.email\" ng-disabled=\"vm.actionInProgress\"> </div> <div class=\"input-row\"> <label class=\"if-no-placeholder\" for=\"email\">Password</label> <input type=\"password\" placeholder=\"password\" name=\"password\" required ng-model=\"vm.password\" ng-disabled=\"vm.actionInProgress\"> </div> <div ng-show=\"vm.credentialsIncorrect\" class=\"error-text\"> You username and/or password was incorrect. Please try again. </div> <div ng-show=\"vm.accountDisabled\" class=\"error-text\"> You Book Retriever account has been disabled. </div> <button type=\"submit\" class=\"submit-button\" ng-disabled=\"!vm.canSignIn()\"> <span ng-if=\"vm.actionInProgress\">Signing In...</span> <span ng-if=\"!vm.actionInProgress\">Sign In</span> </button> <div class=\"clearfix\"> <div class=\"remember-me\"> <input type=\"checkbox\" name=\"remember\" ng-model=\"vm.remember\" ng-disabled=\"vm.actionInProgress\"> Remember me </div> <div class=\"forgot-password\"> <a ui-sref=\"forgotPassword\">Forgot your password?</a> </div> </div> <div class=\"sso\" ng-show=\"vm.showCleverButton()\"> <a ui-state=\"'clever.oauth'\" ui-state-params=\"vm.getCleverOAuthStateParams()\"> <img src=\"/bust-{{CACHE_BUSTER}}/images/clever-sso-button.png\" alt=\"Sign in with Clever\"> </a> </div> <div class=\"alternate-flow\"> <p>Don’t have an account? <a ui-sref=\"register\">Register for free!</a></p> </div> </form> ");
}]);
})();
