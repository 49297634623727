/**
 * Functions backported from lodash 4.x
 */
(function() {
    'use strict';

    attachBackports();

    window.lodash = window._;

    function attachBackports() {
        var originalMemoize = _.memoize;

        _.mixin(_, {
            isInteger: function(value) {
                return _.isNumber(value) && _.isFinite(value) && value % 1 === 0;
            },
            isNil: function(value) {
                return value === undefined || value === null;
            },
            memoize: NewMemoize,
        }, {
            chain: false,
        });

        function NewMemoize() {
            var args = Array.prototype.slice.call(arguments);
            var memoizedFunction = originalMemoize.apply(this, args);

            memoizedFunction.cache.clear = clear;

            return memoizedFunction;

            function clear() {
                memoizedFunction.cache = new NewMemoize.Cache();
                memoizedFunction.cache.clear = clear;
            }
        }
        NewMemoize.Cache = originalMemoize.Cache;
    }
})();
