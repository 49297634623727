(function() {
    'use strict';

    angular
        .module('shared.validators')
        .directive('requireMatches', requireMatchesDirective);

    function requireMatchesDirective() {
        var directive = {
            link: requireMatchesLink,
            require: 'ngModel',
            restrict: 'A',
        };

        return directive;
    }

    function requireMatchesLink($scope, $element, $attrs, model) {
        model.$validators.matches = requireMatches;

        function requireMatches(modelValue, viewValue) {
            if (model.$isEmpty(modelValue)) {
                return true;
            }

            if (viewValue !== $attrs.requireMatches) {
                return false;
            }

            return true;
        }
    }
})();
