(function() {
    'use strict';

    angular
        .module('auth.resetPassword')
        .controller('ForgotPasswordController', ForgotPasswordController);

    ForgotPasswordController.$inject = [
        '$log',
        '$window',
        'apiService',
    ];

    function ForgotPasswordController() {
        var vm = this;
        var di = diHelper(ForgotPasswordController.$inject, arguments);

        activate();

        function activate() {
            di.$log.debug('activated forgot password controller');

            vm.actionInProgress = false;
            vm.emailErrors = {};
            vm.sent = false;

            // Function bindings
            vm.canReset = canReset;
            vm.hasErrors = hasErrors;
            vm.resetPassword = resetPassword;
        }

        function canReset() {
            if (!vm.resetForm || vm.resetForm.$invalid) {
                return false;
            }

            if (vm.actionInProgress) {
                return false;
            }

            return true;
        }

        function hasErrors() {
            return _.size(vm.emailErrors) > 0;
        }

        function resetPassword() {
            if (!vm.canReset()) {
                return;
            }

            vm.actionInProgress = true;

            di.apiService
                .post({
                    endpoint: ['auth', 'password', 'forgot'],
                    data: {
                        email: vm.email,
                    },
                })
                .finally(function() {
                    vm.emailErrors = null;
                })
                .then(
                    function() {
                        vm.sent = true;
                    },
                    function(reason) {
                        if (_.has(reason.data, 'error')) {
                            var error = reason.data.error;

                            if (error === 'emailBad') {
                                vm.emailErrors = {
                                    email: true,
                                };
                            }
                        }
                    }
                )
                .finally(function() {
                    vm.actionInProgress = false;
                });
        }
    }
})();
