(function() {
    'use strict';

    angular
        .module('shared.analytics')
        .config(configureGoogleAnalytics)
        .run(runGoogleAnalytics);

    configureGoogleAnalytics.$inject = [
        '$windowProvider',

        'googleAnalyticsServiceProvider',
    ];

    function configureGoogleAnalytics($windowProvider, googleAnalyticsServiceProvider) {
        var $window = $windowProvider.$get();

        if ($window.GA_PROPERTY_ID) {
            googleAnalyticsServiceProvider.setPropertyId($window.GA_PROPERTY_ID);
        }
    }

    runGoogleAnalytics.$inject = [
        'googleAnalyticsService',
    ];

    function runGoogleAnalytics(googleAnalyticsService) {
        googleAnalyticsService.initialize();
    }
})();
