(function() {
    "use strict";

    angular
        .module("auth.register")
        .controller("RegisterController", RegisterController);

    RegisterController.$inject = ["$log", "$timeout", "$window", "apiService"];

    function RegisterController() {
        var vm = this;
        var di = diHelper(RegisterController.$inject, arguments);

        activate();

        function activate() {
            di.$log.debug("activated register controller");

            vm.actionInProgress = false;

            // Function bindings
            vm.canRegister = canRegister;
            vm.register = register;
            vm.updateEmail = updateEmail;
            vm.updateEmailConfirm = updateEmailConfirm;
        }

        function updateEmail(value) {
            console.log(value);
        }

        function updateEmailConfirm(value) {
            console.log(value);
        }

        function canRegister() {
            if (vm.actionInProgress) {
                return false;
            }

            if (
                !vm.registerForm ||
                vm.registerForm.$pending ||
                vm.registerForm.$invalid
            ) {
                return false;
            }

            return true;
        }

        function register() {
            if (!vm.canRegister()) {
                return;
            }

            vm.actionInProgress = "register";

            di.apiService
                .post({
                    endpoint: ["auth", "register"],
                    data: {
                        email: vm.email
                    }
                })
                .then(
                    function() {
                        fbq("track", "CompleteRegistration");
                        di.$window.location = "/app/welcome";

                        // Delay resetting actionInProgress
                        return di.$timeout(1000);
                    },
                    function(response) {
                        di.$log.error(response);
                    }
                )
                .finally(function() {
                    vm.actionInProgress = false;
                });
        }
    }
})();
