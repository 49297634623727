(function() {
    "use strict";

    angular.module("shared.api").provider("apiService", ApiServiceProvider);

    function ApiServiceProvider() {
        var apiEndpoint = null;

        this.setApiEndpoint = setApiEndpoint;
        this.$get = apiServiceFactory;

        function setApiEndpoint(url) {
            apiEndpoint = url;
        }

        apiServiceFactory.$inject = [
            "$cookies",
            "$http",
            "$q",
            "$rootScope",

            "apiDefaultEndpoint"
        ];

        function apiServiceFactory() {
            var di = diHelper(apiServiceFactory.$inject, arguments);

            di.apiEndpoint = apiEndpoint || di.apiDefaultEndpoint;

            return new ApiService(di);
        }
    }

    function ApiService(di) {
        var self = this;
        var service = {
            call: call,
            get: get,
            post: post,
            put: put,
            destroy: destroy,
            getEndpoint: getEndpoint
        };

        _.forEach(service, function(value, key) {
            self[key] = value;
        });

        function getEndpoint() {
            return di.apiEndpoint;
        }

        function call(options) {
            options = options || {};
            if (!_.has(options, "endpoint")) {
                throw new TypeError("options missing required endpoint");
            }

            if (options.method) {
                options.method = options.method.toLowerCase();
            } else {
                options.method = "get";
            }
            if (_.isArray(options.endpoint)) {
                options.endpoint = options.endpoint.join("/");
            }

            options.data = options.data || {};
            var httpOptions = {};
            var httpHeaders = {};
            var timeoutDefer = di.$q.defer();
            httpOptions.timeout = timeoutDefer.promise;
            httpOptions.url = di.apiEndpoint + "/" + options.endpoint;
            if (_.includes(["post", "put"], options.method)) {
                httpOptions.data = options.data;
            } else {
                httpOptions.params = options.data;
            }

            httpOptions.method = options.method;

            var token = di.$cookies.get("XSRF-TOKEN");
            if (token) {
                httpHeaders["X-XSRF-TOKEN"] = token;
            }

            if (!_.isEmpty(httpHeaders)) {
                httpOptions.headers = httpHeaders;
            }

            if (options.ignoreLoadingBar) {
                httpOptions.ignoreLoadingBar = true;
            }
            var httpPromise = di.$http(httpOptions).then(
                function success(response) {
                    if (_.get(options, "sendEvents", true)) {
                        di.$rootScope.$broadcast(
                            "apiServiceRequestSuccessful",
                            response
                        );
                    }

                    // HTTP 204, No Content
                    if (response.status === 204) {
                        return null;
                    }

                    if (
                        !_.isObject(response.data) ||
                        _.isString(response.data)
                    ) {
                        throw new SyntaxError("response invalid " + response);
                    }

                    return response.data;
                },
                function failure(response) {
                    if (response.status === 402) {
                        di.$rootScope.$broadcast(
                            "apiServiceRequestFailedReadOnly",
                            response
                        );
                    }

                    return di.$q.reject(response);
                }
            );

            httpPromise.abort = function() {
                timeoutDefer.resolve();
            };

            return httpPromise;
        }

        function get(options) {
            options = options || {};
            options.method = "get";
            return call(options);
        }

        function post(options) {
            options = options || {};
            options.method = "post";
            return call(options);
        }

        function put(options) {
            options = options || {};
            options.method = "put";
            return call(options);
        }

        function destroy(options) {
            options = options || {};
            options.method = "delete";
            return call(options);
        }
    }
})();
