(function() {
    'use strict';

    angular
        .module('auth.mobile')
        .controller('MobileIOSController', MobileIOSController);

    MobileIOSController.$inject = [
        '$log',
        '$stateParams',
        '$window',
    ];

    function MobileIOSController() {
        var vm = this;
        var di = diHelper(MobileIOSController.$inject, arguments);

        vm.$onInit = activate;

        function activate() {
            di.$log.debug('activated mobile ios controller');

            goToApp();
        }

        function goToApp() {
            var token = di.$stateParams.token;

            if (!token) {
                return;
            }

            // Make sure the token is valid to prevent any security issues
            if (!token.match(/^[a-z0-9]+$/i)) {
                return;
            }

            di.$window.location.href = 'bookretriever-scanning-app://app/auth/ios?token=' + token;
        }
    }
})();
