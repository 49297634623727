(function() {
    'use strict';

    angular
        .module('shared.routes')
        .config(configure);

    configure.$inject = [
        '$provide',
    ];

    /**
     * @author TaylorMac {@link http://stackoverflow.com/users/720785/taylormac}
     * @see {@link http://stackoverflow.com/a/27255909}
     * @license CC BY-SA 3.0
     * @param {Object} $provide The $provide service
     */
    function configure($provide) {
        $provide.decorator('$state', decorator);

        decorator.$inject = [
            '$delegate',
            '$rootScope',
        ];

        function decorator($delegate, $rootScope) {
            $rootScope.$on('$stateChangeStart', function(event, state, params) {
                $delegate.next = state;
                $delegate.toParams = params;
            });

            return $delegate;
        }
    }
})();
