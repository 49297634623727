(function() {
    'use strict';

    angular
        .module('shared.util')
        .provider('loadingOverlayService', LoadingOverlayServiceProvider);

    function LoadingOverlayServiceProvider() {
        this.$get = loadingOverlayServiceFactory;

        loadingOverlayServiceFactory.$inject = [
            '$q',
        ];

        function loadingOverlayServiceFactory() {
            var di = diHelper(loadingOverlayServiceFactory.$inject, arguments);

            return new LoadingOverlayService(di);
        }
    }

    function LoadingOverlayService(di) {
        var $q = di.$q;

        var overlay = null;
        var overlayLocks = [];

        this.display = display;
        this.setOverlay = setOverlay;

        function addLock(lock) {
            overlayLocks.push(lock);

            return show();
        }

        function display() {
            var lock = {};

            return acquire();

            function acquire() {
                var promise = addLock(lock);

                return {
                    promise: promise,
                    release: release,
                };
            }

            function release() {
                _.remove(overlayLocks, function(overlayLock) {
                    return lock === overlayLock;
                });

                removeLock(lock);
            }
        }

        function hide(fast) {
            if (!overlay) {
                return $q.resolve();
            }

            return overlay.hide(fast);
        }

        function removeLock(lock) {
            _.remove(overlayLocks, function(overlayLock) {
                return overlayLock === lock;
            });

            if (!_.size(overlayLocks)) {
                return hide();
            }

            return null;
        }

        function setOverlay(newOverlay) {
            if (newOverlay) {
                var funcs = [
                    'hide',
                    'show',
                ];

                _.forEach(funcs, function(func) {
                    if (!_.isFunction(newOverlay[func])) {
                        throw new TypeError('newOverlay.' + func + ' must be a function');
                    }
                });
            }

            overlay = newOverlay;
            updateOverlay(true);
        }

        function show(fast) {
            if (!overlay) {
                return $q.resolve();
            }

            return overlay.show(fast);
        }

        function updateOverlay(fast) {
            if (_.size(overlayLocks)) {
                return show(fast);
            }

            return hide(fast);
        }
    }
})();
