// Sourced from https://github.com/dropbox/zxcvbn with modifications

// cross-browser asynchronous script loading for zxcvbn.
// adapted from http://friendlybit.com/js/lazy-loading-asyncronous-javascript/

(function() {
    'use strict';

    var ZXCVBN_SRC = '/bust-' + window.CACHE_BUSTER + '/js/zxcvbn.min.js';

    var asyncLoad = function() {
        var scriptTag = document.createElement('script');
        scriptTag.src = ZXCVBN_SRC;
        scriptTag.type = 'text/javascript';
        scriptTag.async = true;
        var firstScript = document.getElementsByTagName('script')[0];
        return firstScript.parentNode.insertBefore(scriptTag, firstScript);
    };


    window.addEventListener('load', asyncLoad, false);
})();
