(function() {
    'use strict';

    angular
        .module('shared.passwords')
        .directive('tuvaPasswordStrengthMeter', tuvaPasswordStrengthMeter);

    function tuvaPasswordStrengthMeter() {
        var directive = {
            restrict: 'E',
            bindToController: true,
            controller: TuvaPasswordStrengthControllerMeter,
            controllerAs: 'vm',
            scope: {
                password: '&',
                score: '=?passwordScore',
                strongEnough: '=?strongEnough',
            },
            templateUrl: 'templates/shared/passwords/password-strength.directive.html',
        };

        return directive;
    }

    TuvaPasswordStrengthControllerMeter.$inject = [
        '$log',
        '$scope',
        'passwordStrengthService',
    ];

    function TuvaPasswordStrengthControllerMeter() {
        var vm = this;
        var di = diHelper(TuvaPasswordStrengthControllerMeter.$inject, arguments);

        activate();

        function activate() {
            di.$log.debug('activated password strength directive controller');

            vm.score = null;
            vm.strongEnough = false;

            // Function bindings
            vm.getPassword = getPassword;
            vm.getScore = getScore;
            vm.getStrengthText = getStrengthText;
            vm.isStrongEnough = isStrongEnough;
            vm.isZxcvbnReady = isZxcvbnReady;
            vm.updateComplexity = updateComplexity;

            di.$scope.$watch(
                function() {
                    return vm.getPassword();
                },
                function() {
                    vm.updateComplexity();
                }
            );
        }

        function getPassword() {
            if (_.isFunction(vm.password)) {
                return vm.password();
            }
            return '';
        }

        function getScore() {
            if (!vm.getPassword()) {
                return 0;
            }

            return vm.score || 1;
        }

        function getStrengthText() {
            if (!getScore()) {
                return null;
            }

            var texts = [
                'Terrible',
                'Poor',
                'Good',
                'Great!',
            ];

            return texts[getScore() - 1];
        }

        function isStrongEnough() {
            return vm.score && vm.score >= 3;
        }

        function isZxcvbnReady() {
            return _.isFunction(window.zxcvbn);
        }

        function updateComplexity() {
            var complexity = di.passwordStrengthService.calculateComplexity(vm.getPassword());

            vm.feedback = [];
            vm.score = null;
            vm.strongEnough = false;
            vm.warning = null;

            if (!complexity) {
                return;
            }

            vm.complexity = complexity;

            vm.score = complexity.score;
            vm.feedback = complexity.feedback;

            vm.strongEnough = vm.isStrongEnough();
        }
    }
})();
