(function() {
    'use strict';

    angular
        .module('shared.validators')
        .directive('number', numberValidatorDirective);

    var DECIMAL_REGEX = /^-?\d+(?:\.\d+)?$/;
    var INTEGER_REGEX = /^-?\d+$/;

    function numberValidatorDirective() {
        var directive = {
            link: numberValidatorLink,
            require: 'ngModel',
            restrict: 'A',
        };

        return directive;
    }

    function numberValidatorLink($scope, $element, $attrs, model) {
        model.$validators.number = numberValidator;
        model.$formatters.push(numberFormatter);

        /**
         * Formats numeric strings
         * @param {*} value The value to format
         * @return {*} The formatted value
         */
        function numberFormatter(value) {
            // Passthrough non-string values
            if (!_.isString(value)) {
                return value;
            }

            if (value === '') {
                return null;
            }

            return parseFloat(value);
        }

        function numberValidator(modelValue, viewValue) {
            if (model.$isEmpty(modelValue)) {
                return true;
            }

            var value = parseFloat(viewValue);
            if (!_.isFinite(value)) {
                return false;
            }

            var testRegex = DECIMAL_REGEX;

            if ($attrs.number === 'integer') {
                testRegex = INTEGER_REGEX;
            }

            if (!testRegex.test(viewValue)) {
                return false;
            }

            // TODO: Use proper floating-point comparison
            if (_.has($attrs, 'min')) {
                var min = parseFloat($attrs.min);
                if (value < min) {
                    return false;
                }
            }

            if (_.has($attrs, 'max')) {
                var max = parseFloat($attrs.max);
                if (value > max) {
                    return false;
                }
            }

            if (_.has($attrs, 'maxDecimals')) {
                var maxDecimals = parseInt($attrs.maxDecimals, 10);
                var parts = viewValue.split('.');
                if (parts.length > 1) {
                    var decimal = parts[1];

                    // TODO: Trim trailing 0's
                    if (decimal.length > maxDecimals) {
                        return false;
                    }
                }
            }

            return true;
        }
    }
})();
