(function() {
    'use strict';

    /**
     * Global convenience function to help with Angular dependency injection.
     * Prevents the parameter count explosion that can happen with DI.
     *
     * @param {Array} injected The $inject property of the injectable function.
     * @param {Array} args The arguments variable in the injected function.
     *
     * @return {Object} The injected arguments, indexed by name.
     */
    window.diHelper = function(injected, args) {
        var di = {};
        _.forEach(args, function(val, index) {
            di[injected[index]] = val;
        });

        return di;
    };
})();
