(function() {
    'use strict';

    angular
        .module('shared.api')
        .config(configure);

    configure.$inject = [
        'globalResolveProvider',
    ];

    function configure(globalResolveProvider) {
        // Add requireBootstrapped as a resolve parameter to each route
        globalResolveProvider.resolveGlobally('ensureBootstrapped', requireBootstrapped);
    }

    requireBootstrapped.$inject = [
        'bootstrapService',
    ];

    function requireBootstrapped() {
        var di = diHelper(requireBootstrapped.$inject, arguments);

        return di.bootstrapService.bootstrap();
    }
})();
