(function() {
    'use strict';

    angular
        .module('shared.util')
        .provider('utilFunctions', UtilFunctionsProvider);

    function UtilFunctionsProvider() {
        this.$get = utilFunctionsFactory;

        utilFunctionsFactory.$inject = [
            '$interval',
        ];

        function utilFunctionsFactory() {
            var di = diHelper(utilFunctionsFactory.$inject, arguments);

            return utilFunctions(di);
        }
    }

    function utilFunctions(di) {
        return {
            memoizeResource: memoizeResource,
            scopedInterval: scopedInterval,
            sort: sort,
            strip$: strip$,
            toId: toId,
        };

        function memoizeResource(func) {
            var lastValue;
            var alive = false;
            var called = false;

            wrappedFunction.isAlive = isAlive;

            return wrappedFunction;

            function isAlive() {
                if (!called) {
                    wrappedFunction();
                }

                return alive;
            }

            function wrappedFunction() {
                called = true;

                // eslint-disable-next-line no-invalid-this
                var value = func.call();
                if (_.isNil(value)) {
                    alive = false;

                    return lastValue;
                }

                alive = true;
                lastValue = value;

                return value;
            }
        }

        function scopedInterval($scope, func, delay) {
            var promise = di.$interval(func, delay);

            $scope.$on('$destroy', function() {
                di.$interval.cancel(promise);
            });

            return promise;
        }

        function sort(items, sortBy, reverse) {
            // Make shallow copy to allow in-place modifications on array
            var results = items.slice();

            if (sortBy) {
                if (_.isArray(sortBy)) {
                    _.forEach(sortBy.slice().reverse(), function(sortKey, index) {
                        if (_.isArray(reverse) && reverse[index]) {
                            results = results.reverse();
                        }

                        results = _.sortBy(results, sortKey);

                        if (_.isArray(reverse) && reverse[index]) {
                            results = results.reverse();
                        }
                    });
                } else {
                    results = _.sortBy(results, sortBy);
                }
            }

            if (reverse && !_.isArray(sortBy)) {
                results = results.reverse();
            }

            return results;
        }

        function strip$(obj) {
            return _.pick(obj, function(value, key) {
                return !_.startsWith(key, '$');
            });
        }

        function toId(value, key) {
            key = key || 'id';

            if (_.isObject(value)) {
                return _.get(value, key);
            }

            return value;
        }
    }
})();
