(function() {
    'use strict';

    angular
        .module('shared.util')
        .config(defineTypes);

    defineTypes.$inject = [
        '$urlMatcherFactoryProvider',
    ];

    function defineTypes() {
        var di = diHelper(defineTypes.$inject, arguments);

        di.$urlMatcherFactoryProvider.type('id', {}, function() {
            var matcher = {
                decode: function(value) {
                    return parseInt(value, 10);
                },
                encode: function(value) {
                    if (!value) {
                        return null;
                    }

                    return '' + value;
                },
                equals: function(a, b) {
                    return matcher.is(a) && a === b;
                },
                is: function(value) {
                    return _.isInteger(value);
                },
                pattern: /[1-9]\d*/,
            };

            return matcher;
        });

        di.$urlMatcherFactoryProvider.type('idOrHas', {}, function() {
            var matcher = {
                decode: function(value) {
                    if (value === 'any') {
                        return true;
                    }

                    if (value === 'none') {
                        return false;
                    }

                    return parseInt(value, 10);
                },
                encode: function(value) {
                    if (_.isNil(value)) {
                        return null;
                    }

                    if (value === true) {
                        return 'any';
                    }

                    if (value === false) {
                        return 'none';
                    }

                    return '' + value;
                },
                equals: function(a, b) {
                    return matcher.is(a) && a === b;
                },
                is: function(value) {
                    return _.isBoolean(value) || _.isInteger(value);
                },
                pattern: /[1-9]\d*|any|none/,
            };

            return matcher;
        });
    }
})();
