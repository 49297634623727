(function() {
    'use strict';

    angular
        .module('auth')
        .config(configure);

    configure.$inject = [
        '$locationProvider',
        'cfpLoadingBarProvider',
    ];

    /**
     * Setup some application configuration.
     */
    function configure() {
        var di = diHelper(configure.$inject, arguments);

        // Don't use URL hash routes.
        di.$locationProvider.html5Mode(true);

        // Make sure to disable the loading bar spinner.
        di.cfpLoadingBarProvider.includeSpinner = false;
    }
})();
