(function() {
    'use strict';

    angular
        .module('auth.confirmEmail')
        .controller('ConfirmEmailController', ConfirmEmailController);

    ConfirmEmailController.$inject = [
        '$log',
        '$q',
        '$state',
        '$stateParams',
        'apiService',
    ];

    function ConfirmEmailController() {
        var vm = this;
        var di = diHelper(ConfirmEmailController.$inject, arguments);

        activate();

        function activate() {
            di.$log.debug('activated confirm email controller');

            vm.actionInProgress = false;
            vm.badPassword = false;
            vm.complete = false;
            vm.invalid = false;
            vm.needsPassword = false;

            // Function bindings
            vm.canSetPassword = canSetPassword;
            vm.checkConfirmation = checkConfirmation;
            vm.goToComplete = goToComplete;
            vm.sendConfirmation = sendConfirmation;
            vm.setPassword = setPassword;

            vm.checkConfirmation();
        }

        function canSetPassword() {
            if (vm.complete) {
                return false;
            }

            if (!vm.needsPassword) {
                return false;
            }

            if (!vm.newPassword || !vm.newPassword.trim()) {
                return false;
            }

            if (!vm.passwordStrongEnough) {
                return false;
            }

            return true;
        }

        function checkConfirmation(password) {
            vm.actionInProgress = true;

            vm.sendConfirmation(password)
                .then(
                    function(result) {
                        vm.email = result.email;

                        if (result.confirmed) {
                            vm.complete = true;
                        } else if (result.requirePassword) {
                            vm.needsPassword = true;
                        }
                    },
                    function(reason) {
                        if (!(reason instanceof Error)) {
                            throw reason;
                        }

                        vm.invalid = false;
                        vm.badPassword = false;

                        if (reason.message === 'token') {
                            vm.invalid = true;
                        } else if (reason.message === 'password') {
                            vm.badPassword = true;
                        } else {
                            throw reason;
                        }
                    }
                )
                .finally(function() {
                    vm.actionInProgress = false;
                    if (vm.complete) {
                        vm.goToComplete();
                    }
                });
        }

        function goToComplete() {
            di.$state.go('.complete', undefined, {
                location: 'replace',
            });
        }

        function sendConfirmation(password) {
            var data = {
                token: di.$stateParams.token,
            };

            if (password) {
                data.password = password;
            }

            return di.apiService
                .post({
                    endpoint: ['auth', 'email', 'confirm'],
                    data: data,
                })
                .catch(function(response) {
                    if (response.status === 404) {
                        return di.$q.reject(new Error('token'));
                    }

                    if (response.status === 400) {
                        if (response.data.error === 'passwordBad') {
                            return di.$q.reject(new Error('password'));
                        }
                    }

                    throw new Error();
                });
        }

        function setPassword() {
            if (!vm.canSetPassword()) {
                return;
            }

            vm.checkConfirmation(vm.newPassword);
        }
    }
})();
