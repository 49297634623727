(function() {
    'use strict';

    angular
        .module('shared.util')
        .provider('filterCacheService', FilterCacheServiceProvider);

    function FilterCacheServiceProvider() {
        this.$get = filterCacheServiceFactory;

        filterCacheServiceFactory.$inject = [
            '$rootScope',
            'objectStoreService',
            'utilFunctions',
        ];

        function filterCacheServiceFactory() {
            var di = diHelper(filterCacheServiceFactory.$inject, arguments);

            return new FilterCacheService(di);
        }
    }

    function FilterCacheService(di) {
        this.build = function(name, filterFunction) {
            var cacheKey = 'filterCache.' + name;
            var objectStore = di.objectStoreService.get();

            var wrapperFunction = function(filter) {
                filter = filter || {};

                var cached = objectStore.fetch(filter);
                if (cached) {
                    return cached;
                }

                filter = _.clone(filter);

                var results = filterFunction(filter);

                results = di.utilFunctions.sort(results, filter.sort, filter.reverse);

                if (filter.limit || filter.offset) {
                    var limit = filter.limit;
                    var offset = filter.offset || 0;

                    var end = results.length;
                    if (limit !== undefined || limit !== null) {
                        end = offset + limit;
                    }

                    results = results.slice(offset, end);
                }

                objectStore.store(filter, results);

                return results;
            };

            wrapperFunction.clear = function clearCache() {
                objectStore.clear();
                di.$rootScope.$broadcast(wrapperFunction.getClearedEventName());
            };

            wrapperFunction.getCacheKey = function something() {
                return cacheKey;
            };

            wrapperFunction.getClearedEventName = function filterCacheServiceGetClearedEventName() {
                return wrapperFunction.getCacheKey() + '.cleared';
            };

            return wrapperFunction;
        };
    }
})();
