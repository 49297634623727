(function() {
    'use strict';

    angular
        .module('auth.user')
        .provider('userService', UserServiceProvider);

    function UserServiceProvider() {
        this.$get = userServiceFactory;

        userServiceFactory.$inject = [
            '$window',
        ];

        function userServiceFactory() {
            var di = diHelper(userServiceFactory.$inject, arguments);

            return new UserService(di);
        }
    }

    function UserService(di) {
        var self = this;

        this.getUser = function() {
            return di.$window.user;
        };

        this.guestPage = function() {
            if (self.isSignedIn()) {
                di.$window.location = '/app';
            }
        };

        this.isSignedIn = function() {
            return !!(di.$window.user && di.$window.user.id);
        };
    }
})();
