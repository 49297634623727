(function() {
    'use strict';

    angular
        .module('shared.util')
        .provider('objectStoreService', ObjectStoreServiceProvider);

    function ObjectStoreServiceProvider() {
        this.$get = objectStoreServiceFactory;

        objectStoreServiceFactory.$inject = [
            '$cacheFactory',
        ];

        function objectStoreServiceFactory() {
            var di = diHelper(objectStoreServiceFactory.$inject, arguments);

            return new ObjectStoreService(di);
        }
    }

    function ObjectStoreService(di) {
        this.get = function objectStoreServiceGet(key) {
            if (!key) {
                return new ObjectStore();
            }

            var cache = di.$cacheFactory.get('objectStore.' + key);
            if (!cache) {
                cache = di.$cacheFactory('objectStore.' + key);
            }

            return storeWrapper(cache);
        };
    }

    function ObjectStore() {
        var store = {};

        this.clear = function() {
            store = {};
        };

        this.fetch = function(key) {
            var serializedKey = serializeKey(key);
            if (hasSerialized(serializedKey)) {
                return store[serializedKey];
            }

            return undefined;
        };

        this.has = function(key) {
            return hasSerialized(serializeKey(key));
        };

        this.store = function(key, value) {
            store[serializeKey(key)] = value;
        };

        function hasSerialized(serializedKey) {
            return _.has(store, serializedKey);
        }
    }

    function storeWrapper(cache) {
        return {
            clear: clear,
            has: has,
            store: store,
            fetch: fetch,
        };

        function clear() {
            cache.removeAll();
        }

        function has(key) {
            return cache.get(serializeKey(key)) !== undefined;
        }

        function store(key, value) {
            cache.put(serializeKey(key), value);
        }

        function fetch(key) {
            return cache.get(serializeKey(key));
        }
    }

    function serializeKey(key) {
        return JSON.stringify(key);
    }
})();
