(function() {
    'use strict';

    angular
        .module('auth.clever')
        .controller('CleverChooseController', CleverChooseController);

    CleverChooseController.$inject = [
        '$log',
        '$state',
        '$window',
    ];

    function CleverChooseController() {
        var vm = this;
        var di = diHelper(CleverChooseController.$inject, arguments);

        vm.$onInit = activate;

        function activate() {
            di.$log.debug('activated clever choose controller');

            vm.options = generateOptions();
        }

        function generateOptions() {
            var domains = [
                {
                    domain: 'bookretriever.local',
                    name: 'Local Development',
                },
                {
                    domain: 'bookretriever.dev.tuvastaging.com',
                    name: 'Dev',
                },
                {
                    domain: 'bookretriever.uat.tuvastaging.com',
                    name: 'UAT',
                },
            ];

            var urlSuffix = di.$state.href('clever.oauth', null, {
                absolute: false,
                relative: '',
            });

            urlSuffix += di.$window.location.search;

            _.forEach(domains, function(domain) {
                var url;
                if (domain.secure) {
                    url = 'https://';
                } else {
                    url = 'http://';
                }

                url += domain.domain + urlSuffix;

                domain.url = url;
            });

            return domains;
        }
    }
})();
